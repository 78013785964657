<!-- 学习目标  行政班目标达成率 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        :is-check-input-box="false"
        @onSearch="onSearch"
        :clearSort="clearSort"
        :isDownload="true"
        @onDownload="onDownload"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :current-page="page"
        @onSortChange="sortChange"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    >
      <template slot="title-left">
        <div class="flex flex-align">
          <p>行政班：{{ $route.query.class }}</p>
          <p class="ml-20">班主任：{{ $route.query.name }}</p>
        </div>
      </template>
    </list-template>

    <el-dialog :visible.sync="dialogVisible" v-if="details" custom-class="width-bigger" title="学生学习目标详情">
      <div class="des">
        <div>学生姓名：<span>{{ details.target_info.student_name }}</span></div>
        <div>学号：<span>{{ details.target_info.student_no }}</span></div>
        <div>班主任：<span>{{ details.target_info.admin_teacher }}</span></div>
        <div>行政班：<span>{{ details.target_info.admin_class }}</span></div>
        <div>学校：<span>{{ details.target_info.school_name }}</span></div>
        <div>年级：<span>{{ details.target_info.grade_name }}</span></div>
        <div>目标任务名称：<span>{{ details.target_info.target_name }}</span></div>
        <div style="width: 66%">上次考试名称：<span>{{ details.target_info.exam_name }}</span></div>
      </div>
      <div style="font-size: 16rem;font-weight: bold;color: #333;margin-bottom: 20rem;margin-top: 30rem">目标达成详情</div>
      <el-table :data="details.target_list" style="width: 100%" size="small">
        <template v-for="item in tableConfig2">
          <el-table-column v-if="!item.render" :label="item.label" :prop="item.prop"
                           :show-overflow-tooltip='true' :width="item.width">
          </el-table-column>
          <el-table-column v-else :label="item.label" :prop="item.prop"
                           :show-overflow-tooltip='true' :width="item.width">
            <template slot-scope='scope'>
              <div v-html='item.render(scope.row)'></div>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <span slot="footer">
				<el-button type="primary" @click="dialogVisible = false">关闭</el-button>
			</span>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import tools from "@/utils/tools";

export default {
	_config:{"route":{"path":"student","meta":{"title":"学生列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      search: {},
      searchVal: '',
      searchConfig: [
        {
          prop: "student_name",
          placeholder: "搜索学生姓名",
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
      order_by: '',
      clearSort: false,
      dialogVisible: false,
      details: '',

      tableConfig: [
        {width: "80rem", label: '序号', type: 'number'},
        {prop: "student_name", label: "学生姓名"},
        {prop: "student_no", label: "学号"},
        {prop: "target_num", label: "考核目标总数", sortable: 'custom'},
        {prop: "succ_target_num", label: "达成目标数", sortable: 'custom'},
        {
          prop: "handle",
          label: "操作",
          width: "120rem",
          handle: true,
          render(row) {
            return ["查看"]
          }
        },
      ],

      tableConfig2: [
        {prop: "target_exam_name", label: "本次考试名称", width: '200'},
        {prop: "subject_name", label: "科目"},
        {prop: "teach_class_name", label: "教学班"},
        {prop: "teach_class_teacher_name", label: "教学班老师"},
        {prop: "score_base", label: "上次成绩"},
        {
          prop: "score_target", label: "本次期末目标", width: 120,
          render: (row) => {
            return row.is_set_target === 0 ? `<span style="color: red">${row.score_target}</span>` : `${row.score_target}`
          }
        },
        {prop: "score_actual", label: "本次成绩"},
        {prop: "score_diff", label: "与目标分差"},
      ],
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.getConfig()
    this.getData()
  },
  activated() {
    this.getStatus()
    this.getConfig()
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    getConfig() {
      this.$_axios2('api/study-target-statistic/admin-class-list').then(res => {
        this.searchConfig[0].options = res.data.data || []
      })
    },

    getData() {
      this.loading = true
      let params = {...this.search, page: this.page, id: this.$route.query.id}
      let order_by = this.order_by
      if (order_by) params.order_by = order_by
      this.tableData = [];
      this.$_axios2('api/study-target-statistic/admin-class-students', {params}).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
        this.searchVal = JSON.parse(JSON.stringify(this.search))
      }).finally(() => {
        this.loading = false
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.order_by = ''
      this.clearSort = true
      this.getData(val)
    },

    handleEdit(row, text) {
      this.dialogVisible = true
      this.$_axios2('api/study-target-statistic/student-detail?id=' + row.id).then(res => {
        if (!Array.isArray(res.data.data)) {
          this.details = res.data.data
        }
      })
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage", val);
      //拉取数据
      this.getData()
    },

    onDownload() {
      let params = {...this.searchVal, page: this.page, id: this.$route.query.id}
      let order_by = this.order_by
      if (order_by) params.order_by = order_by
      this.$_axios2('api/study-target-statistic/admin-class-students-export', {params}).then(res => {
        if(typeof res.data === 'string') tools.download('行政班目标达成率-学生列表', res.data)
      })
    },

    sortChange({column, prop, order}) {
      let str;
      str = order ? (order === 'ascending' ? (prop + ' desc') : (prop + ' asc')) : null;
      this.order_by = str
      this.clearSort = false
      this.getData()
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table th.el-table__cell > .cell {
  font-weight: bold;
  font-size: 14rem;
  color: #333;
}

.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}

.des {
  color: #666;
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 33.3%;
    padding-right: 20rem;
    min-width: 33.3%;
    margin-bottom: 20rem;
  }

  span {
    color: #333;
  }
}
</style>
